import { Array, Option } from "@swan-io/boxed";
import { Validator } from "@swan-io/use-form";
import dayjs from "dayjs";
import { isEmpty } from "fleming-lake/src/utils/nullish";
import { isValidEmail, isValidVatNumber } from "fleming-shared-business/src/utils/validation";
import { match } from "ts-pattern";
import {
  OnboardingInvalidInfoFragment,
  UpdateValidationErrorsFragment,
  ValidationFieldErrorCode,
} from "../graphql/unauthenticated";
import { locale, t } from "./i18n";

export const validateRequiredBoolean: Validator<boolean | undefined> = value => {
  if (typeof value != "boolean") {
    return t("error.requiredField");
  }
};

export const validateRequired: Validator<string> = value => {
  if (!value) {
    return t("error.requiredField");
  }
};

export const validateEmail: Validator<string> = value => {
  if (!isValidEmail(value)) {
    return t("error.invalidEmail");
  }
};

export const validateMaxLength: (maxLength: number) => Validator<string> = maxLength => value => {
  if (!value) {
    return;
  }

  if (value.length > maxLength) {
    return " ";
  }
};

export type ServerInvalidFieldCode = "Missing";

export const extractServerValidationErrors = <T extends string>(
  { fields }: UpdateValidationErrorsFragment,
  pathToFieldName: (path: string[]) => T | null = () => null,
): { fieldName: T; code: ValidationFieldErrorCode }[] => {
  return Array.filterMap(fields, ({ path, code }) => {
    const fieldName = pathToFieldName(path);
    if (fieldName != null) {
      return Option.Some({ fieldName, code });
    }
    return Option.None();
  });
};

export const extractServerInvalidFields = <T extends string>(
  statusInfo: OnboardingInvalidInfoFragment,
  getFieldName: (field: string) => T | null,
): { fieldName: T; code: ServerInvalidFieldCode }[] => {
  return match(statusInfo)
    .with({ __typename: "OnboardingInvalidStatusInfo" }, ({ errors }) =>
      Array.filterMap(errors, error => {
        const fieldName = getFieldName(error.field);
        if (fieldName != null) {
          return Option.Some({ fieldName, code: "Missing" as const });
        }
        return Option.None();
      }),
    )
    .otherwise(() => []);
};

export const getValidationErrorMessage = (
  code: ValidationFieldErrorCode | ServerInvalidFieldCode,
  currentValue?: string,
): string => {
  return match(code)
    .with("Missing", () => t("error.requiredField"))
    .with("InvalidString", () =>
      isEmpty(currentValue) ? t("error.requiredField") : t("error.invalidField"),
    )
    .with("InvalidType", "TooLong", "TooShort", () => t("error.invalidField"))
    .with("UnrecognizedKeys", () => t("error.unrecognizedKeys"))
    .exhaustive();
};

export const validateVatNumber: Validator<string> = value => {
  const cleaned = value.replace(/[^A-Z0-9]/gi, "");
  if (cleaned.length === 0) {
    return;
  }

  if (!isValidVatNumber(cleaned)) {
    return t("common.form.invalidVatNumber");
  }
};

export const validateDate: Validator<string> = value => {
  if (!dayjs(value, locale.dateFormat, true).isValid()) {
    return t("common.form.invalidDate");
  }
};

// birthdate can be only in the past, today or tomorrow (to allow timezones) - and after 1900
export const validateBirthdate: Validator<string> = value => {
  const date = dayjs(value, locale.dateFormat);
  if (!date.isValid() || date.year() < 1900) {
    return t("common.form.invalidDate");
  }

  const tomorrow = dayjs().startOf("day").add(1, "day");

  if (date.isAfter(tomorrow)) {
    return t("common.form.birthdateCannotBeFuture");
  }
};

export const validateName: Validator<string> = value => {
  if (!value) {
    return;
  }

  // Rule copied from the backend
  if (value.length > 100) {
    return t("common.form.invalidName");
  }

  // This regex was copied from the backend to ensure that the validation is the same
  // Matches all unicode letters, spaces, dashes, apostrophes, commas, and single quotes
  const isValid = value.match(
    /^(?:[A-Za-zÀ-ÖÙ-öù-ƿǄ-ʯʹ-ʽΈ-ΊΎ-ΡΣ-ҁҊ-Ֆա-ևႠ-Ⴥა-ჺᄀ-፜፩-ᎏᵫ-ᶚḀ-῾ⴀ-ⴥ⺀-⿕ぁ-ゖゝ-ㇿ㋿-鿯鿿-ꒌꙀ-ꙮꚀ-ꚙꜦ-ꞇꞍ-ꞿꥠ-ꥼＡ-Ｚａ-ｚ]| |'|-|Ά|Ό|,)*$/,
  );

  if (!isValid) {
    return t("common.form.invalidName");
  }
};

export const validateLength: Validator<string> = value => {
  if (!value) {
    return;
  }

  // Rule copied from the backend
  if (value.length > 100) {
    return t("common.form.tooLong");
  }
};

export const validateTaxIdentificationNumber: Validator<string> = value => {
  if (!value) {
    return t("error.requiredField");
  }
  if (value.length !== 11 && !value.match("\\d{11}")) {
    return t("common.form.invalidTaxIdentificationNumber");
  }
};

export const validateNullableTaxIdentificationNumber: Validator<string | undefined> = value => {
  if (value == null || !value) {
    return;
  }
  if (value.length !== 11 && !value.match("\\d{11}")) {
    return t("common.form.invalidTaxIdentificationNumber");
  }
};

export const validateTradeRegisterNumber: Validator<string> = value => {
  if (!value) {
    return;
  }

  if (!value.match("[hH][rR][aAbB][ 0-9]+")) {
    return t("common.form.invalidTradeRegisterNumber");
  }
};

export const validatePostCode: Validator<string> = value => {
  if (!value) {
    return;
  }

  if (!value.match("^[0-9]{5}$")) {
    return t("common.form.invalidPostCode");
  }
};
