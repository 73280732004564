import { Result } from "@swan-io/boxed";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { LakeTextInput } from "fleming-lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { breakpoints } from "fleming-lake/src/constants/design";
import { useBoolean } from "fleming-lake/src/hooks/useBoolean";
import { useMutation } from "@swan-io/graphql-client";
import { showToast } from "fleming-lake/src/state/toasts";
import { isNotNullish, isNotNullishOrEmpty } from "fleming-lake/src/utils/nullish";
import { CountryPicker } from "fleming-shared-business/src/components/CountryPicker";
import { PlacekitCityInput } from "fleming-shared-business/src/components/PlacekitCityInput";
import { TaxIdentificationNumberInput } from "fleming-shared-business/src/components/TaxIdentificationNumberInput";
import {
  CountryCCA3,
  allCountries,
  getCCA3forCCA2,
  isCountryCCA2,
  isCountryCCA3,
} from "fleming-shared-business/src/constants/countries";
import { decodeBirthDate, encodeBirthDate } from "fleming-shared-business/src/utils/date";
import { validateNullableRequired } from "fleming-shared-business/src/utils/validation";
import { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { hasDefinedKeys, toOptionalValidator, useForm } from "react-ux-form";
import { Rifm } from "rifm";
import { match } from "ts-pattern";
import { v4 as uuid } from "uuid";
import { OnboardingFooter } from "../../components/OnboardingFooter";
import { OnboardingStepContent } from "../../components/OnboardingStepContent";
import {
  AccountCountry,
  IndividualUltimateBeneficialOwnerInput,
  UboFragment,
  UpdateCompanyOnboardingDocument,
} from "../../graphql/unauthenticated";
import { locale, rifmDateProps, t } from "../../utils/i18n";
import { logFrontendError } from "../../utils/logger";
import { CompanyOnboardingRoute, Router } from "../../utils/routes";
import { getUpdateOnboardingError } from "../../utils/templateTranslations";
import {
  validateBirthdate,
  validateName,
  validateNullableTaxIdentificationNumber,
} from "../../utils/validation";
import {
  Input,
  REFERENCE_SYMBOL,
  validateUbo,
} from "./ownership-beneficiary/OnboardingCompanyOwnershipBeneficiaryForm";

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
  },
});

type FormValues = {
  birthDate: string;
  birthCountryCode: CountryCCA3;
  birthCity: string;
  birthCityPostalCode: string;
  taxIdentificationNumber?: string;
};

const requiredStepFields = ["birthCountryCode"] satisfies (keyof FormValues)[];

type Props = {
  previousStep: CompanyOnboardingRoute;
  nextStep: CompanyOnboardingRoute;
  onboardingId: string;
  accountCountry: AccountCountry;
  country: CountryCCA3;
  companyName: string;
  ubos: UboFragment[];
};

type LocalStateUbo = Partial<Input> & {
  errors: ReturnType<typeof validateUbo>;
};

const convertFetchUboToInput = (fetchedUbo: UboFragment): LocalStateUbo => {
  const { direct, indirect, totalCapitalPercentage } = match<
    UboFragment["info"],
    Partial<
      Pick<IndividualUltimateBeneficialOwnerInput, "direct" | "indirect" | "totalCapitalPercentage">
    >
  >(fetchedUbo.info)
    .with(
      { __typename: "IndividualUltimateBeneficialOwnerTypeHasCapital" },
      ({ direct, indirect, totalCapitalPercentage }) => ({
        direct,
        indirect,
        totalCapitalPercentage,
      }),
    )
    .otherwise(() => ({}));

  const ubo = {
    type: fetchedUbo.info.type,
    firstName: fetchedUbo.firstName ?? "",
    lastName: fetchedUbo.lastName ?? "",
    birthCountryCode: isCountryCCA3(fetchedUbo.birthCountryCode)
      ? fetchedUbo.birthCountryCode
      : isCountryCCA2(fetchedUbo.birthCountryCode)
        ? getCCA3forCCA2(fetchedUbo.birthCountryCode)
        : undefined,
    birthCity: fetchedUbo.birthCity ?? "",
    birthCityPostalCode: fetchedUbo.birthCityPostalCode ?? "",
    // Slice to remove the time part because the backend sends a DateTime instead of a Date
    // https://linear.app/swan/issue/ECU-2938/ubo-birthdate-is-a-datetime-instead-of-a-date
    birthDate: fetchedUbo.birthDate != null ? fetchedUbo.birthDate.slice(0, 10) : "",
    direct: direct ?? false,
    indirect: indirect ?? false,
    totalCapitalPercentage: totalCapitalPercentage ?? undefined,
    residencyAddressLine1: fetchedUbo.residencyAddress?.addressLine1,
    residencyAddressCity: fetchedUbo.residencyAddress?.city,
    residencyAddressCountry: fetchedUbo.residencyAddress?.country,
    residencyAddressPostalCode: fetchedUbo.residencyAddress?.postalCode,
    taxIdentificationNumber: fetchedUbo.taxIdentificationNumber ?? undefined,
  } as Partial<Input>;
  ubo[REFERENCE_SYMBOL] = uuid();

  const errors = validateUbo(ubo, "DEU");

  return {
    ...ubo,
    errors,
  };
};

export const OnboardingCompanySingleOwnership = ({
  previousStep,
  nextStep,
  onboardingId,
  accountCountry,
  ubos,
}: Props) => {
  const [updateOnboarding, updateResult] = useMutation(UpdateCompanyOnboardingDocument);
  const [editableUbos, setEditableUbos] = useState(() =>
    ubos.map(ubo => convertFetchUboToInput(ubo)),
  );
  const [shakeError, setShakeError] = useBoolean(false);

  useEffect(() => {
    if (shakeError) {
      const timeout = setTimeout(() => setShakeError.off(), 800);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [shakeError, setShakeError]);

  const firstUbo = ubos[0];
  const initialState = firstUbo as unknown as Partial<Input>; //TODO can this be done without unknown?
  const initialBirthDate = firstUbo?.birthDate;

  const { Field, FieldsListener, setFieldValue, submitForm } = useForm<FormValues>({
    birthDate: {
      initialValue: isNotNullishOrEmpty(initialBirthDate) ? decodeBirthDate(initialBirthDate) : "",
      validate: toOptionalValidator(validateBirthdate),

      sanitize: value => value?.trim(),
    },
    birthCountryCode: {
      initialValue: initialState?.birthCountryCode ?? accountCountry,
      validate: validateNullableRequired,
    },
    birthCity: {
      initialValue: firstUbo?.birthCity ?? "",
      validate: validateName,
      sanitize: value => value?.trim(),
    },
    birthCityPostalCode: {
      initialValue: firstUbo?.birthCityPostalCode ?? "",
      sanitize: value => value?.trim(),
    },
    taxIdentificationNumber: {
      initialValue: initialState?.taxIdentificationNumber ?? "",
      validate: validateNullableTaxIdentificationNumber,
      sanitize: value => value?.trim(),
    },
  });

  const updateUbo = (ubo: Partial<Input>) => {
    // errors is empty because beneficiaries form already validates the ubo
    setEditableUbos(ubos => {
      {
        ubos[0] = { ...ubos[0], ...ubo, errors: {} } as LocalStateUbo;
        return ubos;
      }
    });
  };

  const onPressPrevious = () => {
    Router.push(previousStep, { onboardingId });
  };

  const submitStep = () => {
    submitForm(values => {
      if (hasDefinedKeys(values, requiredStepFields)) {
        return updateUbo({
          birthDate: values.birthDate != null ? encodeBirthDate(values.birthDate) : null,
          birthCountryCode: values.birthCountryCode,
          birthCity: values.birthCity,
          birthCityPostalCode: values.birthCityPostalCode,
          taxIdentificationNumber: values.taxIdentificationNumber,
        } as Partial<Input>);
      }
    });

    const individualUltimateBeneficialOwners = editableUbos.map(
      ({
        [REFERENCE_SYMBOL]: reference,
        residencyAddressCountry,
        residencyAddressLine1,
        residencyAddressCity,
        residencyAddressPostalCode,
        errors,
        ...ubo
      }) => {
        const residencyAddress = [
          residencyAddressCountry,
          residencyAddressLine1,
          residencyAddressCity,
          residencyAddressPostalCode,
        ].every(isNotNullish)
          ? {
              country: residencyAddressCountry as string,
              addressLine1: residencyAddressLine1 as string,
              city: residencyAddressCity as string,
              postalCode: residencyAddressPostalCode as string,
            }
          : undefined;

        return {
          ...ubo,
          residencyAddress,
        };
      },
    ) as IndividualUltimateBeneficialOwnerInput[];
    updateOnboarding({
      input: {
        onboardingId,
        individualUltimateBeneficialOwners,
      },
      language: locale.language,
    })
      .mapOkToResult(({ unauthenticatedUpdateCompanyOnboarding }) =>
        match(unauthenticatedUpdateCompanyOnboarding)
          .with({ __typename: "UnauthenticatedUpdateCompanyOnboardingSuccessPayload" }, value =>
            Result.Ok(value),
          )
          .otherwise(error => Result.Error(error)),
      )
      .tapOk(() => {
        Router.push(nextStep, { onboardingId });
      })
      .tapError(error => {
        const errorMessage = getUpdateOnboardingError(error);
        showToast({
          variant: "error",
          title: errorMessage.title,
          description: errorMessage.description,
        });
      });
  };

  const onPressNext = () => {
    submitStep();
  };

  return (
    <>
      <OnboardingStepContent>
        <ResponsiveContainer breakpoint={breakpoints.medium}>
          {() => (
            <>
              <Field name="birthDate">
                {({ ref, value, onChange, error }) => (
                  <LakeLabel
                    label={t("company.step.owner.birthDate")}
                    style={styles.inputContainer}
                    render={id => (
                      <Rifm value={value ?? ""} onChange={onChange} {...rifmDateProps}>
                        {({ value, onChange }) => (
                          <LakeTextInput
                            ref={ref}
                            error={error}
                            placeholder={locale.datePlaceholder}
                            id={id}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      </Rifm>
                    )}
                  />
                )}
              </Field>

              <Space width={12} />

              <Field name="birthCountryCode">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("company.step.owner.birthCountry")}
                    style={styles.inputContainer}
                    render={id => (
                      <CountryPicker
                        id={id}
                        error={error}
                        value={value}
                        placeholder={t("company.step.owner.birthCountryPlaceholder")}
                        countries={allCountries}
                        onValueChange={onChange}
                      />
                    )}
                  />
                )}
              </Field>

              <FieldsListener names={["birthCountryCode"]}>
                {({ birthCountryCode }) => (
                  <>
                    <Field name="birthCity">
                      {({ value, onChange, error }) => (
                        <LakeLabel
                          label={t("company.step.owner.birthCity")}
                          style={styles.inputContainer}
                          optionalLabel={t("common.optional")}
                          render={id => (
                            <PlacekitCityInput
                              id={id}
                              apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                              error={error}
                              country={birthCountryCode.value}
                              value={value ?? ""}
                              onValueChange={onChange}
                              placeholder={
                                birthCountryCode.value == null
                                  ? t("company.step.owner.fillBirthCountry")
                                  : t("company.step.owner.birthCityPlaceholder")
                              }
                              onSuggestion={place => {
                                onChange(place.city);
                                setFieldValue("birthCityPostalCode", place.postalCode ?? "");
                              }}
                              onLoadError={logFrontendError}
                            />
                          )}
                        />
                      )}
                    </Field>

                    <Space width={12} />

                    <Field name="birthCityPostalCode">
                      {({ value, onChange, error }) => (
                        <LakeLabel
                          label={t("company.step.owner.birthPostalCode")}
                          style={styles.inputContainer}
                          optionalLabel={t("common.optional")}
                          render={id => (
                            <LakeTextInput
                              error={error}
                              placeholder={
                                birthCountryCode.value == null
                                  ? t("company.step.owner.fillBirthCountry")
                                  : t("company.step.owner.birthPostalCodePlaceholder")
                              }
                              id={id}
                              disabled={birthCountryCode.value === undefined}
                              value={value}
                              onChangeText={onChange}
                            />
                          )}
                        />
                      )}
                    </Field>
                  </>
                )}
              </FieldsListener>

              <Field name="taxIdentificationNumber">
                {({ value, error, valid, onChange }) => (
                  <TaxIdentificationNumberInput
                    value={value ?? ""}
                    error={error}
                    valid={valid}
                    onChange={onChange}
                    accountCountry={accountCountry}
                    isCompany={false}
                    // is mandatory for German accounts and UBO living in Germany
                    required={false}
                  />
                )}
              </Field>
            </>
          )}
        </ResponsiveContainer>
      </OnboardingStepContent>

      <OnboardingFooter
        onPrevious={onPressPrevious}
        onNext={onPressNext}
        loading={updateResult.isLoading()}
      />
    </>
  );
};
