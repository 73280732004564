export type PickListGroup = {
    name: string,
    items: PickListEntry[]
}


export type PickListEntry = {
    name: string,
    value: string,
}

export type MedicalSpecialisationPickList = (PickListEntry| PickListGroup)[]

export const medicalSpecialisationPickList:MedicalSpecialisationPickList = [
    { name: "Humanmedizin", items: [
        { value: "General Practitioner", name: "Allgemeinmedizin" },
        { value: "Anaesthetist", name: "Anästhesie" },
        { value: "Occupational Practitioner", name: "Arbeitsmedizin" },
        { value: "Occulist", name: "Augenmedizin" },
        { value: "Surgeon", name: "Chirurgie" },
        { value: "Child Surgeon", name: "Kinder- / Jugend-Chirurgie" },
        { value: "Neurosurgeon", name: "Neurochirurgie" },
        { value: "Cosmetic Surgeon", name: "Plastische Chirurgie" },
        { value: "Dermatologist", name: "Dermatologie" },
        { value: "Gynaecologist", name: "Gynäkologie" },
        { value: "ENT Specialist", name: "HNO" },
        { value: "Internist", name: "Innere Medizin" },
        { value: "Cardiologist", name: "Kardiologie" },
        { value: "Oncologist", name: "Onkologie" },
        { value: "Pulmonologist", name: "Pneumologie" },
        { value: "Rheumatologist", name: "Rheumatologie" },
        { value: "Paediatrist", name: "Kinder- und Jugendmedizin" },
        { value: "Neurologist", name: "Neurologie" },
        { value: "Nuclear Medecine", name: "Nuklearmedizin" },
        { value: "Orthopaedist", name: "Orthopädie" },
        { value: "Psychiatrist", name: "Psychiatrie und Psychotherapie" },
        { value: "Child Psychiatrist", name: "Kinder- / Jugend-Psychiatrie" },
        { value: "Physiologist", name: "Physiologie" },
        { value: "Radiologist", name: "Radiologie" },
        { value: "Radiotherapist", name: "Strahlentherapie" },
        { value: "Urologist", name: "Urologie" },
    ]},
    { name: "Zahnmedizin", items: [
        { value: "Dentist", name: "Allgemeine Zahnmedizin" },
        { value: "Orthodonstist", name: "Kieferorthopädie" },
    ]},
    { name: "Therapie", items: [
        { value: "Occupational Therapist", name: "Ergotherapie" },
        { value: "Speech Therapist", name: "Logopädie" },
        { value: "Physical Therapist", name: "Physiotherapie" },
        { value: "Psycho Therapist", name: "Psychotherapie" },
    ]},
    { value: "Pharmacist", name: "Apotheke" },
    { value: "Nursery", name: "Pflege" },
    { value: "Other", name: "Sonstige" },
]

