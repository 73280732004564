import { useMutation } from "@swan-io/graphql-client";
import { LakeHeading } from "fleming-lake/src/components/LakeHeading";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { RadioGroup, RadioGroupItem } from "fleming-lake/src/components/RadioGroup";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { Tile } from "fleming-lake/src/components/Tile";
import { breakpoints } from "fleming-lake/src/constants/design";
import { showToast } from "fleming-lake/src/state/toasts";
import { filterRejectionsToResult } from "fleming-lake/src/utils/gql";
import { CountryCCA3 } from "fleming-shared-business/src/constants/countries";
import { translateError } from "fleming-shared-business/src/utils/i18n";
import { useEffect, useState } from "react";
import { hasDefinedKeys, useForm } from "react-ux-form";
import { OnboardingFooter } from "../../components/OnboardingFooter";
import { OnboardingStepContent } from "../../components/OnboardingStepContent";
import {
  CompanyType,
  TypeOfRepresentation,
  UpdateCompanyOnboardingDocument,
} from "../../graphql/unauthenticated";
import { locale, t } from "../../utils/i18n";
import { CompanyOnboardingRoute, Router } from "../../utils/routes";

const defaultCountry = "DEU";
const defaultTypeOfRepresentation: TypeOfRepresentation = "LegalRepresentative";

type BasicInfoValues = {
  country: CountryCCA3;
  typeOfRepresentation: TypeOfRepresentation;
  companyType: CompanyType;
};

type Props = {
  nextStep: CompanyOnboardingRoute;
  onboardingId: string;
  initialValues: BasicInfoValues;
};

const companyTypesPerCountry: Partial<Record<CountryCCA3, string>> = {
  BEL: "(SA, SPRL, SCRL, SCRIS, SNC, SCS, GIE)",
  DEU: "(Praxisgemeinschaft, Gemeinschaftspraxis, MVZ, usw.)",
  FRA: "(SA, SARL, SAS, SCI…)",
  ITA: "(SS, SRL, SPA, SNC, SAS…)",
  LUX: "(SA, SCS, SARLI, SNC, SCA, SC)",
  NLD: "(BV, NV, VOF…)",
};

const getCompanyTypes = (country: CountryCCA3): RadioGroupItem<CompanyType>[] => {
  const items: RadioGroupItem<CompanyType>[] = [];

  items.push({ name: t("companyType.selfEmployed"), value: "SelfEmployed" });
  items.push({
    name: `${t("companyType.company")} ${companyTypesPerCountry[country] ?? ""}`,
    value: "Company",
  });

  return items;
};

export const OnboardingCompanyBasicInfo = ({ nextStep, onboardingId, initialValues }: Props) => {
  const [updateOnboarding, updateResult] = useMutation(UpdateCompanyOnboardingDocument);

  const { Field, submitForm, listenFields, setFieldValue } = useForm({
    country: {
      initialValue: defaultCountry,
    },
    typeOfRepresentation: {
      initialValue: defaultTypeOfRepresentation,
    },
    companyType: {
      initialValue: initialValues.companyType,
    },
  });
  const [companyTypes, setCompanyTypes] = useState<RadioGroupItem<CompanyType>[]>(() =>
    getCompanyTypes(initialValues.country),
  );

  useEffect(() => {
    return listenFields(["country", "companyType"], ({ companyType }) => {
      const companyTypes = getCompanyTypes(defaultCountry);
      const companyTypeIsAvailable = companyTypes.some(({ value }) => value === companyType.value);
      if (!companyTypeIsAvailable) {
        setFieldValue("companyType", "Company");
      }
      setCompanyTypes(companyTypes);
    });
  }, [listenFields, setFieldValue]);

  const onPressNext = () => {
    submitForm(values => {
      if (!hasDefinedKeys(values, ["companyType"])) {
        return;
      }

      const {
        country = defaultCountry,
        typeOfRepresentation = defaultTypeOfRepresentation,
        companyType,
      } = values;

      updateOnboarding({
        input: {
          onboardingId,
          companyType,
          typeOfRepresentation,
          residencyAddress: { country },
          language: locale.language,
        },
        language: locale.language,
      })
        .mapOk(data => data.unauthenticatedUpdateCompanyOnboarding)
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => Router.push(nextStep, { onboardingId }))
        .tapError(error => {
          // No need to add specific message depending on validation
          // because all fields are select or radio (so we can't have syntax error)
          // and all fields have a default value (so we can't have missing value)
          showToast({ variant: "error", error, title: translateError(error) });
        });
    });
  };

  return (
    <>
      <OnboardingStepContent>
        <ResponsiveContainer breakpoint={breakpoints.medium}>
          {({ small }) => (
            <>
              <LakeHeading
                level={1}
                variant={small ? "h3" : "h1"}
                align={small ? "center" : "left"}
              >
                {t("company.step.basicInfo.title")}
              </LakeHeading>

              <Space height={small ? 8 : 12} />

              <LakeText align={small ? "center" : "left"}>
                {t("company.step.basicInfo.description")}
              </LakeText>

              <Space height={small ? 24 : 32} />

              <Tile>
                <LakeText variant="semibold">
                  {t("company.step.basicInfo.onlyDeutschland")}
                </LakeText>

                <Space height={small ? 24 : 32} />

                <Field name="companyType">
                  {({ value, onChange }) => (
                    <LakeLabel
                      label={t("company.step.basicInfo.organisationTypeLabel")}
                      type="radioGroup"
                      render={() => (
                        <RadioGroup items={companyTypes} value={value} onValueChange={onChange} />
                      )}
                    />
                  )}
                </Field>
              </Tile>
            </>
          )}
        </ResponsiveContainer>

        <OnboardingFooter onNext={onPressNext} loading={updateResult.isLoading()} />
      </OnboardingStepContent>
    </>
  );
};
