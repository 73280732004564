import { AutoWidthImage } from "fleming-lake/src/components/AutoWidthImage";
import { Box } from "fleming-lake/src/components/Box";
import { Fill } from "fleming-lake/src/components/Fill";
import { ProjectEnvTag } from "fleming-lake/src/components/ProjectEnvTag";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { spacings } from "fleming-lake/src/constants/design";
import { StyleSheet } from "react-native";
import { env } from "../utils/env";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    width: "100%",
    maxWidth: 1280,
    marginHorizontal: "auto",
  },
  containerDesktop: {
    paddingHorizontal: 40,
    paddingVertical: spacings[24],
  },
  logo: {
    maxWidth: "120px",
  },
});

type Props = {
  projectName: string;
  projectLogo: string;
};

export const OnboardingHeader = ({ projectName, projectLogo }: Props) => {
  const isSandbox = env.SWAN_ENVIRONMENT !== "LIVE";

  return (
    <ResponsiveContainer>
      {({ large, small }) => (
        <Box
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={[styles.container, large && styles.containerDesktop]}
        >
          <AutoWidthImage
            ariaLabel={projectName}
            sourceUri={projectLogo}
            height={small ? 30 : 40}
            resizeMode="contain"
            style={styles.logo}
          />

          {isSandbox && (
            <>
              <Space width={12} />
              <ProjectEnvTag projectEnv="Sandbox" iconOnly={small} />
            </>
          )}

          <Fill minWidth={12} />
        </Box>
      )}
    </ResponsiveContainer>
  );
};
