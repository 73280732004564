export const env = {
  VERSION: __env.VERSION,
  IS_SWAN_MODE: __env.IS_SWAN_MODE,
  SWAN_ENVIRONMENT: __env.SWAN_ENVIRONMENT,
  PLACEKIT_API_KEY: __env.CLIENT_PLACEKIT_API_KEY,
  TGGL_API_KEY: __env.TGGL_API_KEY,
  BANKING_URL: __env.BANKING_URL,
  SENTRY_DSN: __env.CLIENT_SENTRY_DSN,
  PAPPERS_API_URL: "",
};
