import { useMutation } from "@swan-io/graphql-client";
import { Box } from "fleming-lake/src/components/Box";
import { Icon } from "fleming-lake/src/components/Icon";
import { LakeCheckbox } from "fleming-lake/src/components/LakeCheckbox";
import { LakeHeading } from "fleming-lake/src/components/LakeHeading";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { LakeTextInput } from "fleming-lake/src/components/LakeTextInput";
import { Link } from "fleming-lake/src/components/Link";
import { Pressable } from "fleming-lake/src/components/Pressable";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { Tile } from "fleming-lake/src/components/Tile";
import { breakpoints, colors } from "fleming-lake/src/constants/design";
import { useFirstMountState } from "fleming-lake/src/hooks/useFirstMountState";
import { showToast } from "fleming-lake/src/state/toasts";
import { noop } from "fleming-lake/src/utils/function";
import { isNotNullish } from "fleming-lake/src/utils/nullish";
import { filterRejectionsToResult } from "fleming-lake/src/utils/gql";
import {
  AddressDetail,
  PlacekitAddressSearchInput,
} from "fleming-shared-business/src/components/PlacekitAddressSearchInput";
import { CountryCCA3 } from "fleming-shared-business/src/constants/countries";
import { useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import { combineValidators, hasDefinedKeys, useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { OnboardingFooter } from "../../components/OnboardingFooter";
import { OnboardingStepContent } from "../../components/OnboardingStepContent";
import { StepTitle } from "../../components/StepTitle";
import {
  AccountCountry,
  AddressInformation,
  IndividualUltimateBeneficialOwnerInput,
  UboFragment,
  UnauthenticatedUpdateCompanyOnboardingInput,
  UpdateCompanyOnboardingDocument,
} from "../../graphql/unauthenticated";
import { formatNestedMessage, locale, t } from "../../utils/i18n";
import { CompanyOnboardingRoute, Router } from "../../utils/routes";
import { getUpdateOnboardingError } from "../../utils/templateTranslations";
import {
  ServerInvalidFieldCode,
  extractServerValidationErrors,
  getValidationErrorMessage,
  validateEmail,
  validateName,
  validateRequired,
} from "../../utils/validation";

// exclude USA from country list because we can't open account for American citizens
// https://support.swan.io/hc/en-150/articles/5767279299741
// const countryItems = Lazy(() => allCountriesItems.filter(item => item.cca3 !== "USA"));

const styles = StyleSheet.create({
  tcuCheckbox: {
    top: 3, // center checkbox with text
    flexDirection: "row",
    alignItems: "center",
  },
  link: {
    color: colors.partner[500],
    textDecorationLine: "underline",
  },
  linkIcon: {
    marginLeft: 4,
    display: "inline-block",
    verticalAlign: "middle",
  },
  inputContainer: {
    flex: 1,
  },
});

export type RegistrationFieldName =
  | "email"
  | "firstName"
  | "lastName"
  | "address"
  | "city"
  | "postalCode"
  | "country";

const DEFAULT_COUNTRY = "DEU";

type Props = {
  previousStep: CompanyOnboardingRoute;
  nextStep: CompanyOnboardingRoute;
  onboardingId: string;
  initialEmail: string;
  initialFirstName: string;
  initialLastName: string;
  initialAddressLine1: string;
  initialCity: string;
  initialPostalCode: string;
  initialCountry: CountryCCA3;
  projectName: string;
  accountCountry: AccountCountry;
  serverValidationErrors: {
    fieldName: RegistrationFieldName;
    code: ServerInvalidFieldCode;
  }[];
  tcuDocumentUri?: string;
  tcuUrl: string;
  ubos: UboFragment[];
  isSelfEmployed: boolean;
};

export const OnboardingCompanyRegistration = ({
  previousStep,
  nextStep,
  onboardingId,
  initialEmail,
  initialFirstName,
  initialLastName,
  initialAddressLine1,
  initialCity,
  initialPostalCode,
  initialCountry,
  projectName,
  accountCountry,
  serverValidationErrors,
  tcuDocumentUri,
  tcuUrl,
  ubos,
  isSelfEmployed,
}: Props) => {
  const [updateOnboarding, updateResult] = useMutation(UpdateCompanyOnboardingDocument);
  const isFirstMount = useFirstMountState();

  const haveToAcceptTcu = accountCountry === "DEU";
  const isAddressRequired = match(accountCountry)
    .with("DEU", "NLD", () => true)
    .otherwise(() => false);

  const { Field, submitForm, setFieldValue, setFieldError, FieldsListener } = useForm({
    email: {
      initialValue: initialEmail,
      validate: combineValidators(validateRequired, validateEmail),
      sanitize: value => value.trim(),
    },
    firstName: {
      initialValue: initialFirstName,
      validate: combineValidators(isSelfEmployed && validateRequired, validateName),
      sanitize: value => value.trim(),
    },
    lastName: {
      initialValue: initialLastName,
      validate: combineValidators(isSelfEmployed && validateRequired, validateName),
      sanitize: value => value.trim(),
    },
    address: {
      initialValue: initialAddressLine1,
      validate: isAddressRequired ? validateRequired : undefined,
      sanitize: value => value.trim(),
    },
    city: {
      initialValue: initialCity,
      validate: isAddressRequired ? validateRequired : undefined,
      sanitize: value => value.trim(),
    },
    postalCode: {
      initialValue: initialPostalCode,
      validate: isAddressRequired ? validateRequired : undefined,
      sanitize: value => value.trim(),
    },
    country: {
      initialValue: initialCountry,
      validate: isAddressRequired ? validateRequired : undefined,
    },
    tcuAccepted: {
      initialValue: !haveToAcceptTcu, // initialize as accepted if not required
      validate: value => {
        if (value === false) {
          return t("step.finalize.termsError");
        }
      },
    },
    representationAcknowledged: {
      initialValue: false,
      validate: value => {
        if (value === false) {
          return t("step.finalize.termsError");
        }
      },
    },
  });

  useEffect(() => {
    if (isFirstMount) {
      serverValidationErrors.forEach(({ fieldName, code }) => {
        const message = getValidationErrorMessage(code);
        setFieldError(fieldName, message);
      });
    }
  }, [serverValidationErrors, isFirstMount, setFieldError]);

  const onPressPrevious = () => {
    Router.push(previousStep, { onboardingId });
  };

  const onPressNext = () => {
    submitForm(values => {
      if (!hasDefinedKeys(values, ["email"])) {
        return;
      }

      const { email, firstName, lastName, address, city, postalCode, country } = values;

      if (isSelfEmployed) {
        if (ubos.length > 0 && ubos[0] !== undefined) {
          ubos[0].firstName = firstName;
          ubos[0].lastName = lastName;
          if (isNotNullish(ubos[0].residencyAddress)) {
            ubos[0].residencyAddress.addressLine1 = address ?? "";
            //not sure why this is needed, without this they seem to be set null and the server complains
            ubos[0].residencyAddress.addressLine2 = ubos[0].residencyAddress.addressLine2 ?? "";
            ubos[0].residencyAddress.city = city ?? "";
            ubos[0].residencyAddress.postalCode = postalCode ?? "";
            ubos[0].residencyAddress.country = country ?? DEFAULT_COUNTRY;
            ubos[0].residencyAddress.state = ubos[0].residencyAddress.state ?? "";
            ubos[0].info.type = "HasCapital";
            //            ubos[0].info.totalCapitalPercentage = 100; //TODO: how to set this?
            ubos[0].taxIdentificationNumber = ubos[0].taxIdentificationNumber ?? "";
            ubos[0].birthCityPostalCode = ubos[0].birthCityPostalCode ?? "";
            ubos[0].birthCity = ubos[0].birthCity ?? "";
            ubos[0].birthCountryCode = ubos[0].birthCountryCode ?? DEFAULT_COUNTRY;
          } else {
            ubos[0].residencyAddress = {
              addressLine1: address ?? "",
              addressLine2: "",
              city: city ?? "",
              postalCode: postalCode ?? "",
              country: country ?? DEFAULT_COUNTRY,
            } as AddressInformation;
          }
        } else {
          const ubo = {
            firstName,
            lastName,
            info: {
              type: "HasCapital",
              totalCapitalPercentage: 100,
              direct: true,
            },
            residencyAddress: {
              addressLine1: address ?? "",
              city: city ?? "",
              postalCode: postalCode ?? "",
              country: country ?? DEFAULT_COUNTRY,
              state: "",
            } as AddressInformation,
            taxIdentificationNumber: "",
            birthCountryCode: DEFAULT_COUNTRY,
          } as UboFragment;
          ubos.push(ubo);
        }
      }

      const owners = isSelfEmployed
        ? ubos.map(
            ({ __typename, info, ...ubo }) =>
              ({
                ...info,
                ...ubo,
              }) as IndividualUltimateBeneficialOwnerInput,
          )
        : undefined;

      owners?.forEach(owner => {
        // @ts-expect-error 1 beer for the person who makes this nice, whatever "this" is
        delete owner["__typename"]; //TODO: figure out how to do this nicely
        if (isNotNullish(owner.residencyAddress)) {
          // @ts-expect-error 1 beer for the person who makes this nice, whatever "this" is
          delete owner.residencyAddress["__typename"]; //TODO: figure out how to do this nicely
        }
      });

      const updateInput: UnauthenticatedUpdateCompanyOnboardingInput = isAddressRequired
        ? {
            onboardingId,
            email,
            language: locale.language,
            legalRepresentativePersonalAddress: {
              addressLine1: address ?? "",
              city: city ?? "",
              postalCode: postalCode ?? "",
              country: country ?? DEFAULT_COUNTRY,
            },
            individualUltimateBeneficialOwners: owners,
          }
        : {
            onboardingId,
            email,
            language: locale.language,
          };

      updateOnboarding({
        input: updateInput,
        language: locale.language,
      })
        .mapOk(data => data.unauthenticatedUpdateCompanyOnboarding)
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => Router.push(nextStep, { onboardingId }))
        .tapError(error => {
          match(error)
            .with({ __typename: "ValidationRejection" }, error => {
              const invalidFields = extractServerValidationErrors(error, path =>
                match(path)
                  .with(["email"] as const, ([fieldName]) => fieldName)
                  .with(
                    ["legalRepresentativePersonalAddress", "addressLine1"],
                    () => "address" as const,
                  )
                  .with(
                    ["legalRepresentativePersonalAddress", "city"] as const,
                    ([, fieldName]) => fieldName,
                  )
                  .with(
                    ["legalRepresentativePersonalAddress", "postalCode"] as const,
                    ([, fieldName]) => fieldName,
                  )
                  .otherwise(() => null),
              );
              invalidFields.forEach(({ fieldName, code }) => {
                const message = getValidationErrorMessage(code, values[fieldName]);
                setFieldError(fieldName, message);
              });
            })
            .otherwise(noop);

          showToast({ variant: "error", error, ...getUpdateOnboardingError(error) });
        });
    });
  };

  const onSuggestion = useCallback(
    (place: AddressDetail) => {
      setFieldValue("address", place.completeAddress);
      setFieldValue("city", place.city);
      if (place.postalCode != null) {
        setFieldValue("postalCode", place.postalCode);
      }
    },
    [setFieldValue],
  );

  return (
    <>
      <OnboardingStepContent>
        <ResponsiveContainer breakpoint={breakpoints.medium}>
          {({ small }) => (
            <>
              <StepTitle isMobile={small}>{t("company.step.registration.title")}</StepTitle>
              <Space height={small ? 8 : 12} />
              <LakeText>{t("company.step.registration.description")}</LakeText>
              <Space height={small ? 24 : 32} />

              <Tile>
                <Field name="email">
                  {({ value, valid, onChange, error, ref }) => (
                    <LakeLabel
                      label={t("company.step.registration.emailLabel")}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          ref={ref}
                          placeholder="example@gmail.com"
                          value={value}
                          onChangeText={onChange}
                          valid={valid}
                          error={error}
                        />
                      )}
                    />
                  )}
                </Field>
              </Tile>

              <Space height={small ? 24 : 32} />

              {isAddressRequired && (
                <>
                  {isSelfEmployed && (
                    <>
                      <Field name="firstName">
                        {({ ref, value, onChange, error }) => (
                          <LakeLabel
                            label={t("company.step.registration.firstName")}
                            style={styles.inputContainer}
                            render={id => (
                              <LakeTextInput
                                ref={ref}
                                error={error}
                                placeholder={t("company.step.registration.firstNamePlaceholder")}
                                id={id}
                                value={value}
                                onChangeText={onChange}
                              />
                            )}
                          />
                        )}
                      </Field>

                      <Space width={12} />

                      <Field name="lastName">
                        {({ ref, value, onChange, error }) => (
                          <LakeLabel
                            label={t("company.step.registration.lastName")}
                            style={styles.inputContainer}
                            render={id => (
                              <LakeTextInput
                                ref={ref}
                                error={error}
                                placeholder={t("company.step.registration.lastNamePlaceholder")}
                                id={id}
                                value={value}
                                onChangeText={onChange}
                              />
                            )}
                          />
                        )}
                      </Field>
                    </>
                  )}

                  <LakeHeading align="left" variant="h4" level={4}>
                    {t("company.step.registration.onlyDeutschland")}
                  </LakeHeading>

                  <Space height={12} />

                  <FieldsListener names={["country"]}>
                    {({ country }) => (
                      <>
                        <Field name="address">
                          {({ ref, value, onChange, error }) => (
                            <LakeLabel
                              label={t("company.step.registration.searchAddressLabel")}
                              optionalLabel={t(
                                "company.step.registration.searchAddressLabelDetail",
                              )}
                              render={id => (
                                <PlacekitAddressSearchInput
                                  inputRef={ref}
                                  apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                                  emptyResultText={t("common.noResult")}
                                  placeholder={t(
                                    "company.step.registration.searchAddressPlaceholder",
                                  )}
                                  language={locale.language}
                                  id={id}
                                  country={country.value}
                                  value={value}
                                  error={error}
                                  onValueChange={onChange}
                                  onSuggestion={onSuggestion}
                                />
                              )}
                            />
                          )}
                        </Field>

                        <Space height={12} />

                        <Field name="city">
                          {({ ref, value, valid, error, onChange }) => (
                            <LakeLabel
                              label={t("company.step.registration.cityLabel")}
                              render={id => (
                                <LakeTextInput
                                  ref={ref}
                                  id={id}
                                  value={value}
                                  valid={valid}
                                  error={error}
                                  onChangeText={onChange}
                                />
                              )}
                            />
                          )}
                        </Field>

                        <Space height={12} />

                        <Field name="postalCode">
                          {({ ref, value, valid, error, onChange }) => (
                            <LakeLabel
                              label={t("company.step.registration.postalCodeLabel")}
                              render={id => (
                                <LakeTextInput
                                  ref={ref}
                                  id={id}
                                  value={value}
                                  valid={valid}
                                  error={error}
                                  onChangeText={onChange}
                                />
                              )}
                            />
                          )}
                        </Field>
                      </>
                    )}
                  </FieldsListener>
                </>
              )}

              <Space height={small ? 24 : 32} />

              <Box alignItems="start">
                <Box direction="row" justifyContent="start">
                  {haveToAcceptTcu && (
                    <>
                      <Field name="tcuAccepted">
                        {({ value, error, onChange, ref }) => (
                          <>
                            <Pressable
                              ref={ref}
                              role="checkbox"
                              aria-checked={value}
                              onPress={() => onChange(!value)}
                              style={styles.tcuCheckbox}
                            >
                              <LakeCheckbox value={value} isError={isNotNullish(error)} />
                            </Pressable>

                            <Space width={8} />

                            <LakeText>
                              {formatNestedMessage("emailPage.terms", {
                                firstLink: (
                                  <Link target="blank" to={tcuUrl} style={styles.link}>
                                    {t("emailPage.firstLink")}

                                    <Icon name="open-filled" size={16} style={styles.linkIcon} />
                                  </Link>
                                ),
                                secondLink: (
                                  <Link
                                    target="blank"
                                    to={tcuDocumentUri ?? "#"}
                                    style={styles.link}
                                  >
                                    {t("emailPage.secondLink", { partner: projectName })}

                                    <Icon name="open-filled" size={16} style={styles.linkIcon} />
                                  </Link>
                                ),
                                thirdLink: (
                                  <Link
                                    target="blank"
                                    to={t("links.agbPdf") ?? "#"}
                                    style={styles.link}
                                  >
                                    {t("emailPage.thirdLink", { partner: projectName })}

                                    <Icon name="open-filled" size={16} style={styles.linkIcon} />
                                  </Link>
                                ),
                                fourthLink: (
                                  <Link
                                    target="blank"
                                    to={t("links.dataProtectionPdf") ?? "#"}
                                    style={styles.link}
                                  >
                                    {t("emailPage.fourthLink", { partner: projectName })}

                                    <Icon name="open-filled" size={16} style={styles.linkIcon} />
                                  </Link>
                                ),
                              })}
                            </LakeText>
                          </>
                        )}
                      </Field>

                      <Space width={12} />
                    </>
                  )}

                  {!haveToAcceptTcu && (
                    <LakeText>
                      {formatNestedMessage("emailPage.terms", {
                        firstLink: (
                          <Link target="blank" to={tcuUrl} style={styles.link}>
                            {t("emailPage.firstLink")}

                            <Icon name="open-filled" size={16} style={styles.linkIcon} />
                          </Link>
                        ),
                        secondLink: (
                          <Link target="blank" to={tcuDocumentUri ?? "#"} style={styles.link}>
                            {t("emailPage.secondLink", { partner: projectName })}

                            <Icon name="open-filled" size={16} style={styles.linkIcon} />
                          </Link>
                        ),
                        thirdLink: (
                          <Link target="blank" to={t("links.agbPdf") ?? "#"} style={styles.link}>
                            {t("emailPage.thirdLink", { partner: projectName })}

                            <Icon name="open-filled" size={16} style={styles.linkIcon} />
                          </Link>
                        ),
                        fourthLink: (
                          <Link
                            target="blank"
                            to={t("links.dataProtectionPdf") ?? "#"}
                            style={styles.link}
                          >
                            {t("emailPage.fourthLink", { partner: projectName })}

                            <Icon name="open-filled" size={16} style={styles.linkIcon} />
                          </Link>
                        ),
                      })}
                    </LakeText>
                  )}
                </Box>

                {haveToAcceptTcu && (
                  <>
                    <Space height={4} />

                    <FieldsListener names={["tcuAccepted"]}>
                      {({ tcuAccepted }) => (
                        <LakeText color={colors.negative[500]}>{tcuAccepted.error ?? " "}</LakeText>
                      )}
                    </FieldsListener>
                  </>
                )}
              </Box>

              <Box alignItems="start">
                <Box direction="row">
                  <Field name="representationAcknowledged">
                    {({ value, error, onChange, ref }) => (
                      <Pressable
                        ref={ref}
                        aria-checked={value}
                        onPress={() => onChange(!value)}
                        style={styles.tcuCheckbox}
                      >
                        <LakeCheckbox value={value} isError={isNotNullish(error)} />
                      </Pressable>
                    )}
                  </Field>

                  <Space width={12} />
                  <LakeText>{t("step.finalize.acknowledgeRepresentation")}</LakeText>
                </Box>

                <Space height={4} />

                <FieldsListener names={["representationAcknowledged"]}>
                  {({ representationAcknowledged }) => (
                    <LakeText color={colors.negative[500]}>
                      {representationAcknowledged.error ?? " "}
                    </LakeText>
                  )}
                </FieldsListener>
              </Box>
            </>
          )}
        </ResponsiveContainer>

        <OnboardingFooter
          onPrevious={onPressPrevious}
          onNext={onPressNext}
          loading={updateResult.isLoading()}
        />
      </OnboardingStepContent>
    </>
  );
};
