import { useMutation } from "@swan-io/graphql-client";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { Item, LakeSelect } from "fleming-lake/src/components/LakeSelect";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { Tile } from "fleming-lake/src/components/Tile";
import { breakpoints } from "fleming-lake/src/constants/design";
import { useFirstMountState } from "fleming-lake/src/hooks/useFirstMountState";
import { showToast } from "fleming-lake/src/state/toasts";
import { noop } from "fleming-lake/src/utils/function";
import { emptyToUndefined } from "fleming-lake/src/utils/nullish";
import { filterRejectionsToResult } from "fleming-lake/src/utils/gql";
import { monthlyPaymentVolumes } from "fleming-shared-business/src/constants/business";
import { useEffect } from "react";
import { hasDefinedKeys, useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { OnboardingFooter } from "../../components/OnboardingFooter";
import { OnboardingStepContent } from "../../components/OnboardingStepContent";
import { StepTitle } from "../../components/StepTitle";
import { medicalSpecialisationPickList } from "../../fleming/constants";
import {
  BusinessActivity,
  MonthlyPaymentVolume,
  UpdateCompanyOnboardingDocument,
} from "../../graphql/unauthenticated";
import { locale, t } from "../../utils/i18n";
import { CompanyOnboardingRoute, Router } from "../../utils/routes";
import { getUpdateOnboardingError } from "../../utils/templateTranslations";
import {
  ServerInvalidFieldCode,
  extractServerValidationErrors,
  getValidationErrorMessage,
  validateRequired,
} from "../../utils/validation";

const DEFAULT_ACTIVITY = "Health";

export type Organisation2FieldName =
  | "businessActivity"
  | "businessActivityDescription"
  | "monthlyPaymentVolume";

type Props = {
  previousStep: CompanyOnboardingRoute;
  nextStep: CompanyOnboardingRoute;
  onboardingId: string;
  initialBusinessActivity: BusinessActivity | "";
  initialBusinessActivityDescription: string;
  initialMonthlyPaymentVolume: MonthlyPaymentVolume;
  serverValidationErrors: {
    fieldName: Organisation2FieldName;
    code: ServerInvalidFieldCode;
  }[];
};

const monthlyPaymentVolumeItems: Item<MonthlyPaymentVolume>[] = monthlyPaymentVolumes.map(
  ({ text, value }) => ({
    name: text,
    value,
  }),
);

const medicalSpecialisationItems: Item<string>[] = medicalSpecialisationPickList;

export const OnboardingCompanyOrganisation2 = ({
  previousStep,
  nextStep,
  onboardingId,
  initialBusinessActivity,
  initialBusinessActivityDescription,
  initialMonthlyPaymentVolume,
  serverValidationErrors,
}: Props) => {
  const [updateOnboarding, updateResult] = useMutation(UpdateCompanyOnboardingDocument);
  const isFirstMount = useFirstMountState();

  const { Field, submitForm, setFieldError } = useForm({
    businessActivity: {
      initialValue: initialBusinessActivity,
      validate: validateRequired,
    },
    businessActivityDescription: {
      initialValue: initialBusinessActivityDescription,
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    monthlyPaymentVolume: {
      initialValue: initialMonthlyPaymentVolume,
    },
  });

  useEffect(() => {
    if (isFirstMount) {
      serverValidationErrors.forEach(({ fieldName, code }) => {
        const message = getValidationErrorMessage(code);
        setFieldError(fieldName, message);
      });
    }
  }, [serverValidationErrors, isFirstMount, setFieldError]);

  const onPressPrevious = () => {
    Router.push(previousStep, { onboardingId });
  };

  const onPressNext = () => {
    submitForm(values => {
      if (
        !hasDefinedKeys(values, ["businessActivityDescription", "monthlyPaymentVolume"]) ||
        values.businessActivity === ""
      ) {
        return;
      }

      const {
        businessActivity = DEFAULT_ACTIVITY,
        businessActivityDescription,
        monthlyPaymentVolume,
      } = values;

      updateOnboarding({
        input: {
          onboardingId,
          businessActivity,
          businessActivityDescription,
          monthlyPaymentVolume,
          language: locale.language,
        },
        language: locale.language,
      })
        .mapOk(data => data.unauthenticatedUpdateCompanyOnboarding)
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => Router.push(nextStep, { onboardingId }))
        .tapError(error => {
          match(error)
            .with({ __typename: "ValidationRejection" }, error => {
              const invalidFields = extractServerValidationErrors(error, path =>
                path[0] === "businessActivityDescription" ? "businessActivityDescription" : null,
              );
              invalidFields.forEach(({ fieldName, code }) => {
                const message = getValidationErrorMessage(code, values[fieldName]);
                setFieldError(fieldName, message);
              });
            })
            .otherwise(noop);

          showToast({ variant: "error", error, ...getUpdateOnboardingError(error) });
        });
    });
  };

  return (
    <>
      <OnboardingStepContent>
        <ResponsiveContainer breakpoint={breakpoints.medium}>
          {({ small }) => (
            <>
              <StepTitle isMobile={small}>{t("company.step.organisation2.title")}</StepTitle>
              <Space height={small ? 24 : 32} />

              <Tile>
                <Field name="businessActivityDescription">
                  {({ value, error, onChange, ref }) => (
                    <LakeLabel
                      label={t("company.step.organisation2.descriptionLabel")}
                      render={id => (
                        <LakeSelect
                          placeholder={t("company.step.organisation2.descriptionPlaceholder")}
                          value={emptyToUndefined(value)}
                          items={medicalSpecialisationItems}
                          error={error}
                          onValueChange={onChange}
                          ref={ref}
                          id={id}
                        />
                      )}
                    />
                  )}
                </Field>

                <Space height={12} />

                <Field name="monthlyPaymentVolume">
                  {({ value, onChange, ref }) => (
                    <LakeLabel
                      label={t("company.step.organisation2.monthlyPaymentLabel")}
                      render={id => (
                        <LakeSelect
                          id={id}
                          ref={ref}
                          value={value}
                          items={monthlyPaymentVolumeItems}
                          onValueChange={onChange}
                        />
                      )}
                    />
                  )}
                </Field>
              </Tile>
            </>
          )}
        </ResponsiveContainer>

        <OnboardingFooter
          onPrevious={onPressPrevious}
          onNext={onPressNext}
          loading={updateResult.isLoading()}
        />
      </OnboardingStepContent>
    </>
  );
};
